<template>
    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: auto; margin: 40px;">
        <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; width: 60%; margin: 40px;">
            <div>
                <h1 style="font-size: 51px; color: #0a7373; font-weight: bold;">O site está em manutenção no momento.</h1>
                <h1 style="font-size: 44px; color: #0a7373; font-weight: 300;">Voltaremos em breve.</h1>
            </div>
            <div><img src="/img/manutencao.png" alt="manutencao" style="max-width: 500px;"></div>
        </div>
        <div style="margin-top: 60px;"><img src="/img/logo_color.png" alt="wwcalc" style="max-height: 40px;"></div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    import { portuguese } from '../languages/pt-br'
    import { english } from '../languages/en'

export default {
    name: 'outofservice',
    data: ()=>({
        step: 1,
    }),
    computed:{
    ...mapGetters(['profile']),
    selectedLanguage() {
        if(this.profile != undefined){
        if (this.profile['language'] === 'en') {
            return english;
        } else {
            return portuguese;
        }
        } else if(localStorage.getItem('loginLanguage')){
        if(localStorage.getItem('loginLanguage') === 'en'){
            return english;
        } else {
            return portuguese;
        }
        } else {
        return portuguese;
        }
        },
    },
    methods: {
        changeLanguage(props){
        // this.profile = {
        //     "language": props
        //     }
            window.localStorage.setItem('loginLanguage', props)
            window.location.reload()
        },
        scrollToSlide(slideId) {
            const slideGroup = this.$refs.slideGroup;
            const slideElement = document.getElementById(slideId);
            const index = Array.from(slideGroup.$children).findIndex(child => child.$el === slideElement);
            if (index !== -1) {
                slideGroup.goTo(index);
            }
        }
    }
}
</script>
<style scoped>
    video {
        height: 30vh;
        width: 100%;
        object-fit: cover;
    }
    .shadowBg{
        -webkit-box-shadow: inset 10px 10px 184px 88px rgba(0,0,0,0.75);
        -moz-box-shadow: inset 10px 10px 184px 88px rgba(0,0,0,0.75);
        box-shadow: inset 10px 10px 184px 88px rgba(0,0,0,0.75);
    }
    .loginS{
        -webkit-box-shadow:0px 0px 40px 0px rgba(255,255,255,0.8);
        -moz-box-shadow: 0px 0px 40px 0px rgba(255,255,255,0.8);
        box-shadow: 0px 0px 40px 0px rgba(255,255,255,0.8);
    }
</style>